<template>
  <div class="container py-5">
    <p class="fw-bold">AMWC NORTH AMERICA 2023</p>
    <p>時間：2023 02/23-25</p>
    <p>地點：MIAMI</p>
    <p>
      We're looking forward to a great show at the AMWC in Miami this month! Will you be there?!
      <br>
      Come visit us at Booth #326
    </p>

    <img
      class="img-md rounded"
      src="@/assets/images/zh-TW/news/new_13.jpg"
    />
  </div>
</template>
<script>
export default {};
</script>
